export default [
  // {
  //   key: 'image',
  //   initKey: 'profilePicture',
  //   label: 'field.image',
  //   type: 'async-single-image',
  //   rules: '',
  //   pw: 200,
  //   ph: 200,
  //   fullWidth: false,
  //   cols: 12,
  // },
  {
    key: 'name',
    label: 'field.name',
    rules: 'required|max:100',
    type: 'text',
  },
  {
    key: 'departmentId',
    label: 'field.department',
    rules: 'required',
    type: 'asyn-single-selection',
    repository: 'department',
    selectionKey: 'id',
    selectionLabel: 'name',
  },
  {
    key: 'gender',
    label: 'field.gender',
    rules: 'required',
    type: 'radio',
    options: [
      { text: 'field.male', value: 'male' },
      { text: 'field.female', value: 'female' },
    ],
  },
  {
    key: 'typeId',
    label: 'field.type',
    rules: 'required',
    type: 'radio',
    options: [
      { text: 'field.inspector', value: 2 },
      { text: 'field.logistic', value: 3 },
    ],
  },
  {
    key: 'note',
    label: 'field.note',
    rules: 'max:200',
    type: 'textarea',
    cols: 12,
  },
  {
    label: 'breadcrumb.accountInfomration',
    icon: 'LockIcon',
    type: 'divider',
  },
  {
    key: 'phoneNumber',
    label: 'field.phoneNumber',
    rules: 'required|min:8|max:9',
    prefix: '+855',
    type: 'international-tel',
    cols: 6,
  },
  {
    hide: true,
    skip: true,
    cols: 6,
  },
]
